import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/banner',
      name: 'banner-index',
      component: () => import('@/views/Banner/Index.vue'),
      meta: {
        pageTitle: 'Banner',
        breadcrumb: [
          {
            text: 'Banner',
            active: true,
          },
        ],
      },
    },
    {
      path: '/add-banner',
      name: 'add-banner',
      component: () => import('@/views/Banner/Add.vue'),
      meta: {
        pageTitle: 'Tambah Banner',
        breadcrumb: [
          {
            text: 'Add Banner',
            active: true,
          },
        ],
      },
    },
    {
      path: '/edit-banner/:id',
      name: 'edit-banner',
      component: () => import('@/views/Banner/Edit.vue'),
      meta: {
        pageTitle: 'Edit Banner',
        breadcrumb: [
          {
            text: 'Edit Banner',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/artikel',
      name: 'artikel-index',
      component: () => import('@/views/Artikel/Index.vue'),
      meta: {
        pageTitle: 'Artikel',
        breadcrumb: [
          {
            text: 'Artikel',
            active: true,
          },
        ],
      },
    },
    {
      path: '/add-artikel',
      name: 'add-artikel',
      component: () => import('@/views/Artikel/Add.vue'),
      meta: {
        pageTitle: 'Tambah Artikel',
        breadcrumb: [
          {
            text: 'Add Artikel',
            active: true,
          },
        ],
      },
    },
    {
      path: '/edit-artikel/:id',
      name: 'edit-artikel',
      component: () => import('@/views/Artikel/Edit.vue'),
      meta: {
        pageTitle: 'Edit Artikel',
        breadcrumb: [
          {
            text: 'Edit Artikel',
            active: true,
          },
        ],
      },
    },
    {
      path: '/store',
      name: 'store-index',
      component: () => import('@/views/Store/Index.vue'),
      meta: {
        pageTitle: 'Store',
        breadcrumb: [
          {
            text: 'Store',
            active: true,
          },
        ],
      },
    },
    {
      path: '/add-store',
      name: 'add-store',
      component: () => import('@/views/Store/Add.vue'),
      meta: {
        pageTitle: 'Tambah Store',
        breadcrumb: [
          {
            text: 'Add Store',
            active: true,
          },
        ],
      },
    },
    {
      path: '/edit-store/:id',
      name: 'edit-store',
      component: () => import('@/views/Store/Edit.vue'),
      meta: {
        pageTitle: 'Edit Store',
        breadcrumb: [
          {
            text: 'Edit Store',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password',
      name: 'reset-passwrd',
      component: () => import('@/views/Reset-password.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  // Check Token
  const token = sessionStorage.getItem('token') ?? null
  if (!token && to.name !== 'login') {
    // redirect the user to the login page
    next({ name: 'login' })
  } else {
    next()
  }
})

export default router
